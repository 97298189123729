import { ActionIcon, ThemeIcon, Tooltip } from '@mantine/core';
import * as ai from 'react-icons/ai';
import * as ci from 'react-icons/ci';
import * as fa from 'react-icons/fa';
import * as fa6 from 'react-icons/fa6';
import * as fi from 'react-icons/fi';
import * as gr from 'react-icons/gr';
import * as hi2 from 'react-icons/hi2';
import * as io5 from 'react-icons/io5';
import * as lia from 'react-icons/lia';
import * as lu from 'react-icons/lu';
import * as md from 'react-icons/md';
import * as pi from 'react-icons/pi';
import * as ri from 'react-icons/ri';
import * as rx from 'react-icons/rx';
import * as tb from 'react-icons/tb';
import * as tfi from 'react-icons/tfi';
import * as ti from 'react-icons/ti';
import * as vsc from 'react-icons/vsc';
import type { ActionIconProps, ThemeIconProps, TooltipProps } from '@mantine/core';
import type { MouseEventHandler, ReactNode } from 'react';
import type { IconBaseProps } from 'react-icons/lib';

// https://react-icons.github.io/react-icons

export const AcknowledgedAnnouncementIcon = createIconComponent(io5.IoCheckmark);
export const ActiveAnnouncementIcon = createIconComponent(io5.IoStar);
export const ActivityLogIcon = createIconComponent(rx.RxActivityLog);
export const AddIcon = createIconComponent(io5.IoAdd);
export const Address = createIconComponent(fa6.FaRegAddressCard);
export const AdminOpenQuestion = createIconComponent(ri.RiQuestionnaireLine);
export const AdminPortal = createIconComponent(tb.TbSettings);
export const Announcement = createIconComponent(tfi.TfiAnnouncement);
export const AnnouncementIcon = createIconComponent(io5.IoMegaphone);
export const AnnouncementOutlineIcon = createIconComponent(io5.IoMegaphoneOutline);
export const AppleLogo = createIconComponent(io5.IoLogoApple);
export const ApprovalRequired = createIconComponent(pi.PiUserCircleCheck);
export const Apps = createIconComponent(io5.IoApps);
export const BackArrow = createIconComponent(tb.TbArrowLeft);
export const BackorderedProductsIcon = createIconComponent(ri.RiCalendarScheduleLine);
export const Book = createIconComponent(tb.TbBook2);
export const BudgetIcon = createIconComponent(fa.FaMoneyBill);
export const Calendar = createIconComponent(tb.TbCalendar);
export const CalendarIcon = createIconComponent(io5.IoCalendarNumberOutline);
export const Category = createIconComponent(tb.TbCategory);
export const ChevronDown = createIconComponent(tb.TbChevronDown);
export const ClearIcon = createIconComponent(md.MdClear);
export const Clock = createIconComponent(tb.TbClock);
export const Close = createIconComponent(io5.IoCloseCircleOutline);
export const Collapse = createIconComponent(io5.IoRemoveCircle);
export const ConfidentialStatusSwitch = createIconComponent(md.MdOutlineSyncLock);
export const ContactCenter = createIconComponent(ai.AiOutlinePhone);
export const ContentIcon = createIconComponent(tb.TbFileText);
export const Copy = createIconComponent(io5.IoCopy);
export const Countries = createIconComponent(io5.IoGlobeOutline);
export const CurrenciesIcon = createIconComponent(tb.TbCoin);
export const Customers = createIconComponent(io5.IoStorefrontOutline);
export const DarkModeIcon = createIconComponent(tb.TbMoon);
export const Dashboard = createIconComponent(tb.TbDashboard);
export const DashboardIcon = createIconComponent(rx.RxDashboard);
export const DecrementIcon = createIconComponent(io5.IoRemoveCircle);
export const DefaultFile = createIconComponent(fa.FaFile);
export const DistroOrdersIcon = createIconComponent(lu.LuPackagePlus);
export const Download = createIconComponent(tb.TbDownload);
export const DownloadIcon = createIconComponent(io5.IoDownloadOutline);
export const Edit = createIconComponent(io5.IoCreateOutline);
export const EmailIcon = createIconComponent(md.MdEmail);
export const ErrorIcon = createIconComponent(tb.TbAlertCircleFilled);
export const Expand = createIconComponent(io5.IoAddCircle);
export const ExpeditedShippingIcon = createIconComponent(fa.FaShippingFast);
export const ExpiredToken = createIconComponent(tb.TbClockExclamation);
export const ExportIcon = createIconComponent(tb.TbFileExport);
export const Faq = createIconComponent(fa.FaQuestion);
export const File = createIconComponent(tb.TbFile);
export const FileArchive = createIconComponent(fa.FaFileArchive);
export const FileCode = createIconComponent(fa.FaFileCode);
export const FileExcel = createIconComponent(fa.FaFileExcel);
export const FileImage = createIconComponent(fa.FaFileImage);
export const FileManagerIcon = createIconComponent(md.MdOutlineFileUpload);
export const FilePdf = createIconComponent(fa.FaFilePdf);
export const FilePowerpoint = createIconComponent(fa.FaFilePowerpoint);
export const FileText = createIconComponent(fi.FiFileText);
export const FileWord = createIconComponent(fa.FaFileWord);
export const Filter = createIconComponent(io5.IoFilter);
export const FilterOutlineIcon = createIconComponent(io5.IoFilterCircleOutline);
export const FilterSolidIcon = createIconComponent(io5.IoFilterCircle);
export const FulfillmentConfigurationIcon = createIconComponent(gr.GrDocumentConfig);
export const Globe = createIconComponent(io5.IoGlobe);
export const GoBack = createIconComponent(io5.IoChevronBack);
export const Grid = createIconComponent(io5.IoGrid);
export const Heart = createIconComponent(tb.TbHeart);
export const Home = createIconComponent(tb.TbHome);
export const Image = createIconComponent(io5.IoImage);
export const IncrementIcon = createIconComponent(io5.IoAddCircle);
export const Information = createIconComponent(io5.IoInformationCircleOutline);
export const LeftSidebarCollapse = createIconComponent(tb.TbLayoutSidebar);
export const LeftSidebarExpand = createIconComponent(tb.TbLayoutSidebarFilled);
export const LightModeIcon = createIconComponent(tb.TbSun);
export const LineOfBusiness = createIconComponent(tb.TbBrandApple);
export const LoaderIcon = createIconComponent(fi.FiLoader);
export const Location = createIconComponent(ri.RiMapPin2Line);
export const LocationApproval = createIconComponent(md.MdOutlineAddLocationAlt);
export const Lock = createIconComponent(io5.IoLockClosed);
export const Logout = createIconComponent(tb.TbLogout);
export const Mail = createIconComponent(io5.IoMailOutline);
export const Merchandisers = createIconComponent(tb.TbTruckLoading);
export const MultiplyIcon = createIconComponent(ti.TiTimes);
export const NewOrder = createIconComponent(tb.TbShoppingCart);
export const Notifications = createIconComponent(io5.IoNotifications);
export const OrderSearchIcon = createIconComponent(lu.LuPackageSearch);
export const OrderValueExceeded = createIconComponent(hi2.HiOutlineCurrencyDollar);
export const PartNumberIcon = createIconComponent(io5.IoBarcode);
export const PendingIcon = createIconComponent(io5.IoHourglassOutline);
export const Plant = createIconComponent(md.MdConveyorBelt);
export const Plus = createIconComponent(tb.TbPlus);
export const Products = createIconComponent(ai.AiOutlineProduct);
export const ProfileEditIcon = createIconComponent(md.MdOutlineManageAccounts);
export const Programs = createIconComponent(vsc.VscTypeHierarchySuper);
export const ProjectsIcon = createIconComponent(fa.FaProjectDiagram);
export const QuantityLimitExceeded = createIconComponent(io5.IoWarning);
export const RecurringOrderIcon = createIconComponent(tb.TbCalendarRepeat);
export const Refresh = createIconComponent(io5.IoRefresh);
export const RemoveIcon = createIconComponent(io5.IoRemoveCircleOutline);
export const ReturnsIcon = createIconComponent(tb.TbTruckReturn);
export const RightSidebarCollapse = createIconComponent(tb.TbLayoutSidebarRight);
export const RightSidebarExpand = createIconComponent(tb.TbLayoutSidebarRightFilled);
export const Rocket = createIconComponent(io5.IoRocket);
export const SaveCart = createIconComponent(ci.CiSaveDown2);
export const Search = createIconComponent(io5.IoSearch);
export const SearchAlt = createIconComponent(tb.TbSearch);
export const ShippingMethodIcon = createIconComponent(lia.LiaTruckLoadingSolid);
export const ShoppingBag = createIconComponent(io5.IoBag);
export const ShoppingBagOutline = createIconComponent(io5.IoBagOutline);
export const SortAscIcon = createIconComponent(io5.IoChevronUp);
export const SortDescIcon = createIconComponent(io5.IoChevronDown);
export const SortIcon = createIconComponent(tb.TbSortAscending);
export const StandardShippingIcon = createIconComponent(md.MdOutlineLocalShipping);
export const Stores = createIconComponent(io5.IoStorefrontOutline);
export const SubPrograms = createIconComponent(vsc.VscTypeHierarchySub);
export const Success = createIconComponent(io5.IoCheckmark);
export const SupportIcon = createIconComponent(md.MdOutlineLiveHelp);
export const Trash = createIconComponent(hi2.HiOutlineTrash);
export const UnitOfMeasureIcon = createIconComponent(tb.TbRulerMeasure);
export const Unlock = createIconComponent(io5.IoLockOpen);
export const Upload = createIconComponent(tb.TbUpload);
export const User = createIconComponent(io5.IoPerson);
export const UserRepliedQuestion = createIconComponent(ri.RiQuestionAnswerLine);
export const Users = createIconComponent(io5.IoPeopleOutline);
export const ViewOrderHistory = createIconComponent(tb.TbShoppingCartSearch);
export const Warning = createIconComponent(io5.IoWarning);

export type IconElement = (props: IconElementProps) => ReactNode;
export type IconElementProps = (ThemeIconElementProps | ActionIconElementProps) & {
	tooltip?: string;
	iconProps?: Omit<Partial<IconBaseProps>, 'size'>;
	tooltipProps?: Partial<TooltipProps>;
};

export interface ThemeIconElementProps extends ThemeIconProps {
	onClick?: undefined;
}

export interface ActionIconElementProps extends ActionIconProps {
	onClick: MouseEventHandler<HTMLButtonElement>;
}

function createIconComponent(Icon: (props: IconBaseProps) => ReactNode): IconElement {
	// TODO: Use theme color for the fill prop
	return ({ tooltip, tooltipProps, iconProps, ...props }: IconElementProps) => (
		<Tooltip label={tooltip} disabled={!tooltip} {...tooltipProps}>
			{isActionIconElementProps(props) ? (
				<ActionIcon
					variant='transparent'
					c={props.c ?? 'unset'}
					bg='unset'
					{...(props as ActionIconProps)}
				>
					<Icon
						size='80%'
						color={props.disabled ? 'var(--mantine-color-dimmed)' : undefined}
						{...iconProps}
					/>
				</ActionIcon>
			) : (
				<ThemeIcon
					variant='transparent'
					c={props.c ?? 'unset'}
					color={props.color ?? 'unset'}
					{...(props as ThemeIconProps)}
				>
					<Icon size='80%' {...iconProps} />
				</ThemeIcon>
			)}
		</Tooltip>
	);
}

function isActionIconElementProps(props: IconElementProps): props is ActionIconElementProps {
	return 'onClick' in props;
}
