import axios from 'axios';

import { commentSchema } from '@apple/features/order/models/details';
import type { Comment, Message } from '@apple/features/order/models/details';

import {
	orderLineItemSchema,
	purchaseOrderLineSchema,
	purchaseOrderResponseSchema,
} from '../models/details';
import type {
	ItemQuantity,
	OrderLineItem,
	PurchaseOrderLine,
	PurchaseOrderResponse,
} from '../models/details';

export async function getPurchaseOrder(
	poNumber: string,
	signal?: AbortSignal,
): Promise<PurchaseOrderResponse> {
	const data = (await axios.get<unknown>(`/api/orders/${poNumber}`, { signal })).data;
	return purchaseOrderResponseSchema.parse(data);
}

export async function getPurchaseOrderLines(
	poNumber: string,
	signal?: AbortSignal,
): Promise<PurchaseOrderLine[]> {
	const data = (await axios.get<unknown>(`/api/orders/${poNumber}/line-items`, { signal })).data;
	return purchaseOrderLineSchema.array().parse(data);
}

export async function getComments(poNumber: string, signal?: AbortSignal) {
	const data = (await axios.get<unknown>(`/api/orders/${poNumber}/comments`, { signal })).data;
	return commentSchema.array().parse(data);
}

export async function requestOrderCancellation(purchaseOrderNumber: string) {
	await axios.post(`/api/order-cancellation/${purchaseOrderNumber}/cancel`);
}

export async function saveComment(message: Message, poNumber: string): Promise<Comment> {
	const data = (await axios.post<unknown>(`/api/orders/${poNumber}/comments/add`, message)).data;
	return commentSchema.parse(data);
}

export async function addLine(lineItem: ItemQuantity, poNumber: string): Promise<OrderLineItem[]> {
	const data = (await axios.post<unknown>(`/api/orders/${poNumber}/add-line`, lineItem)).data;
	return orderLineItemSchema.array().parse(data);
}
