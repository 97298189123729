import { useCallback, useState } from 'react';
import { Alert, Button, Group, Modal, Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { getLogger } from 'loglevel';
import type { AxiosProgressEvent } from 'axios';

import { Success, Warning } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';

import { EXCEL_ALLOWED_FILE_TYPES, EXCEL_MAX_FILE_SIZE } from '../models/excel';
import { UploadFile } from './UploadFile';
import { UploadResults } from './UploadResults';
import type { ExcelError, ExcelResult } from '../models/excel';

const log = getLogger('excel-upload');

export type ExcelUploadProps<
	// biome-ignore lint/suspicious/noExplicitAny: type constraint is necessary for generic type
	TItem extends Record<string, any>,
	TKey extends keyof TItem & string,
	TError extends ExcelError<TItem, TKey> = ExcelError<TItem, TKey>,
> = {
	keyProperty: TKey;
	keyLabel: string;
	title?: string;
	opened: boolean;
	close: () => void;
	onUpload: (
		file: File,
		onUploadProgress?: (event: AxiosProgressEvent) => void,
	) => Promise<ExcelResult<TItem, TKey, TError>>;
	onSave: (items: TItem[]) => Promise<void>;
	onDelete: (keys: Array<NonNullable<TItem[TKey]>>) => Promise<void>;
	onConfirmed: () => Promise<void>;
	errorsProperty?: keyof TError;
	maxFileSize?: number;
	allowedFileTypes?: string[];
};

export function ExcelUploadDialog<
	// biome-ignore lint/suspicious/noExplicitAny: type constraint is necessary for generic type
	TItem extends Record<string, any>,
	TKey extends keyof TItem & string,
	TError extends ExcelError<TItem, TKey> = ExcelError<TItem, TKey>,
>({
	keyProperty,
	keyLabel,
	title,
	opened,
	close,
	onUpload,
	onSave,
	onDelete,
	onConfirmed,
	errorsProperty = 'errors',
	maxFileSize = EXCEL_MAX_FILE_SIZE,
	allowedFileTypes = EXCEL_ALLOWED_FILE_TYPES,
}: ExcelUploadProps<TItem, TKey, TError>) {
	const { t } = useTranslation('manage');
	const [file, setFile] = useState<File | undefined>();
	const [uploading, setUploading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [uploadResults, setUploadResults] = useState<
		ExcelResult<TItem, TKey, TError> | undefined
	>();
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);

	const reset = useCallback(() => {
		setFile(undefined);
		setUploadResults(undefined);
		setProgress(0);
		setError(undefined);
	}, []);

	const handleSelectFile = useCallback(
		(file: File) => {
			reset();
			setFile(file);
		},
		[reset],
	);

	const handleClose = useCallback(() => {
		close();
		reset();
	}, [close, reset]);

	const handleUploadClick = useCallback(() => {
		async function upload() {
			setUploadResults(undefined);
			if (file === undefined) return;
			setUploading(true);
			try {
				const result = await onUpload(file, e => {
					setProgress((e.progress ?? 0) * 100);
				});
				setUploadResults(result);
			} catch (error) {
				setError(t('excelUpload.errors.invalidFormat'));
				log.error('Error uploading template', error);
			} finally {
				setUploading(false);
			}
		}

		void upload();
	}, [file, onUpload, t]);

	const handleContinueClick = useCallback(() => {
		async function save() {
			if (uploadResults === undefined) {
				return;
			}

			setError(undefined);
			setSaving(true);
			try {
				await Promise.all([
					onSave(uploadResults.inserts.concat(uploadResults.updates)),
					onDelete([...uploadResults.deletes]),
				]);
				notifications.show({
					message: t('excelUpload.results.success'),
					icon: <Success />,
					color: 'green',
					autoClose: 5000,
				});
				handleClose();
			} catch (error) {
				setError(t('common:error.generic'));
				log.error('Error saving updates', error);
			} finally {
				await onConfirmed();
				setSaving(false);
			}
		}

		void save();
	}, [handleClose, onConfirmed, onDelete, onSave, t, uploadResults]);

	return (
		<Modal
			opened={opened}
			onClose={handleClose}
			title={
				uploadResults ? t('excelUpload.results.title') : (title ?? t('excelUpload.title'))
			}
			closeOnEscape={false}
			closeOnClickOutside={false}
			size='xl'
		>
			<Stack p='md'>
				{!uploadResults ? (
					<UploadFile
						onSelectFile={handleSelectFile}
						progress={progress}
						uploading={uploading}
						allowedFileTypes={allowedFileTypes}
						maxFileSize={maxFileSize}
					/>
				) : (
					<UploadResults
						processing={saving}
						results={uploadResults}
						keyLabel={keyLabel}
						keyProperty={keyProperty}
						errorsProperty={errorsProperty}
					/>
				)}
				{error && (
					<Alert color='red' icon={<Warning />}>
						{error}
					</Alert>
				)}
				<Group justify='space-between' gap='xl' mt='md'>
					<Button onClick={reset} disabled={!uploadResults}>
						{t('excelUpload.buttons.back')}
					</Button>
					<Group justify='flex-end' gap='xl'>
						<Button
							variant='light'
							disabled={uploading || saving}
							onClick={handleClose}
						>
							{t('common:buttons.cancel')}
						</Button>
						{!uploadResults ? (
							<Button
								loading={uploading}
								onClick={handleUploadClick}
								disabled={file === undefined}
							>
								{t('common:buttons.upload')}
							</Button>
						) : (
							<Button
								loading={saving}
								disabled={
									!uploadResults ||
									(uploadResults.updates.length === 0 &&
										uploadResults.inserts.length === 0 &&
										uploadResults.deletes.length === 0)
								}
								onClick={handleContinueClick}
							>
								{t('excelUpload.buttons.continueWithTemplate')}
							</Button>
						)}
					</Group>
				</Group>
			</Stack>
		</Modal>
	);
}
