import { extend } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';

export function configDayjs() {
	extend(isSameOrAfter);
	extend(isSameOrBefore);

	// Allows localized formatting of dates
	// https://day.js.org/docs/en/plugin/localized-format
	extend(localizedFormat);
}
